@import "../node_modules/@material/react-drawer/dist/drawer.css";

:root {
  --mdc-theme-primary: #0e3254;
}

.App {
  text-align: center;
  position: relative;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  display: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 .App h1 {
   text-align: left;
   margin-left: 60px;
 }
 
.census-tract-grid {
  display: flex;
  flex-flow: row wrap;
  max-height: 80vh;
  overflow: auto;
  justify-content: center;
}

.census-tract-grid-item {
  flex: 1 1 300px;
  margin: 20px 0px;
  box-sizing: border-box;
}

.census-tract-grid-item:hover {
  border: 2px solid #cccccc;
  cursor: pointer;
}

.census-tract-grid-item img {
  width: 100%;
  height: auto;
}

.census-tract-grid-item h3 {
  text-align: left;
}

.leaflet-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 500px;
  width: 100%;
}

/* .leaflet-control-container {
  display: none;
  opacity:1;
} */

iframe {
  border: 0;
  width: 100%;
  height: 300px;
}

.mdc-dialog__content img {
  width: 100%;
}

.mdc-floating-label {
  color: #0e3254 !important;
}

.App .grid-controls {
  display: flex;
  justify-content: space-between;
  padding: 0 80px;
  margin-top: 20px;
}

/* .App .mdc-dialog__content {
  padding: 0;
  width: 70vw;
  max-width: 100%;
  height: 500px;
} */

/* .App .census-tract-map h2 {
  display: none;
} */

.App .census-tract-map {
  /* position: static; */
}

.App .mdc-drawer {
  left: 0;
  right: 0;
  margin: auto;
  width: 80vw;
  top: 100%;
  bottom: 100%;
  height: fit-content;
}

.App .tangram-container {
  /* height: 300px; */
  max-height: 500px;
  border: 0px;
}

.App .ypccc-county-map {
  width: 100%;
}

.App.map-fixed .ypccc-county-map {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #1f1f1f;
}

.App .ypccc-county-map {
  margin: 0 auto;
  /* width: 80vw; */
}

@media(min-width:1024px){
  .census-tract-grid {
    justify-content: flex-start;
  }
  .census-tract-grid-item {
    flex: 0 4 calc(100vw/3);
  }
}

@media(max-width: 769px){
  .grid-controls {
    flex-direction: column;
    align-items: center;
  }

  .mdc-tab-bar {
    margin-bottom: 40px;
  }
}